.images {
  height: auto;
  /* min-height: 40px; */
  /* max-height: 80px; */
  width: auto;
  /* max-width: 150px; */
}



.trash2:hover{opacity:.7;cursor: pointer;}
.exlink:hover{opacity:.7}
.btn-dropdown > details > summary{background-color: '#1d91c0';}